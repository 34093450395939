@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700');

body {
    color: #fff;
    background-color: #000;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
}

#title {
    margin-top: 6px;
}

.center-container-outer {
    display: table;
    position: absolute;
    width: 100%;
    top: 80px;
    height: calc(100vh - 80px);
}

#main-container-outer {
    top: 170px;
    height: calc(100vh - 170px);
}

.center-container-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.custom-button {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    background-color: #001FB8;
    margin: 10px;
    padding: 20px 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.custom-button:disabled {
    opacity: 0.3;
    cursor: default;
}

#stake-header {
    text-align: center;
    padding: 20px;
}

#connect-wallet-button {
    position: absolute;
    display: inline;
    top: 80px;
    right: 10px;
}

.custom-text-input {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    background-color: #001FB8;
    margin: 10px;
    padding: 16px 12px;
    border: none;
    width: 240px;
    border-radius: 20px;
}

.main-container {
    display: inline-flex;
}

.stake-unstake-container-outer {
    display: table;
    margin: 20px;
}

.stake-unstake-container-inner {
    display: table-cell;
    border: 1px solid #fff;
    padding: 20px;
    border-radius: 20px;
    vertical-align: middle;
}

#fee-disclaimer {
    font-size: 12px;
    margin: 20px;
}

@media screen and (max-width: 1200px) {
    .main-container {
        display: block;
    }

    .stake-unstake-container-outer {
        display: table;
        margin: 20px auto;
        padding: 0 20px;
    }

    #title {
        margin-top: 160px;
    }

    #main-container-outer {
        top: 240px;
        height: calc(100vh - 240px);
    }
}

@media screen and (max-width: 375px) {
    .custom-text-input {
        width: 200px;
    }
}
