/* ----------------------------------------------------------------
	Custom CSS

	Add all your Custom Styled CSS here for New Styles or
	Overwriting Default Theme Styles for Better Handling Updates
-----------------------------------------------------------------*/

html {
  scroll-padding-top: 92px;
}

body {
  color: #fff !important;
  font-family: "Montserrat", sans-serif !important;
  background-color: #000 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.font-body,
.font-primary,
.font-secondary,
p {
  font-family: "Montserrat", sans-serif !important;
  color: #fff !important;
}

h1 {
  font-weight: bold !important;
  text-transform: uppercase !important;
}

h2 {
  font-weight: bold !important;
  font-size: 40px !important;
}

h3 {
  font-weight: bold !important;
  font-size: 32px !important;
  margin-top: 16px !important;
}

p a,
a code {
  text-decoration: underline !important;
  color: #fff !important;
}

p a:hover,
a:hover code {
  color: #ccc !important;
}

ul {
  list-style-position: inside;
}

::selection {
  background: #333;
}

.container {
  padding-left: 24px;
  padding-right: 24px;
}

.svg-trigger path {
  stroke: #fff;
}

#content {
  background-color: #000 !important;
}

.contract-address {
  word-wrap: break-word !important;
  word-break: break-all !important;
  color: #fff !important;
}

.sub-menu-container {
  background-color: #000;
}

#social-links {
  text-align: center;
  padding: 12px;
}

#social-links img {
  margin: 12px;
}

.rounded-corners {
  border-radius: 20px;
}

body:not(.stretched) #wrapper {
  max-width: inherit;
}

@media (max-width: 1199.98px) {
  html {
    scroll-padding-top: 20px;
  }
}
