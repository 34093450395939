#header {
	border: 0;
	width: 100%;
	z-index: 100;
	position: fixed;
}

#header-wrap {
	background-color: #000 !important;
	border-bottom: 1px solid white !important;
}

.primary-menu {
	border: 0 !important;
}

.menu-container {
	padding-left: 0 !important;
}

.menu-item {
	display: table;
	height: 72px;
	border: none !important;
}

.menu-link {
	display: table-cell;
	vertical-align: middle;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
	font-family: "Montserrat", sans-serif;
	color: #fff !important;
}

.dropdown-container::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  height: 11px;
}

.dropdown-container {
	margin-top: 10px;
	display: none;
	position: absolute;
	background-color: #000;
	z-index: 100;
	border-radius: 20px;
	border: 1px solid #fff;
	padding: 10px 0;
	white-space: nowrap;
}

.dropdown-container a {
	float: none;
	display: block;
	text-align: left;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	padding: 8px 20px;
}

.dropdown:hover .dropdown-container {
	display: block;
}

#logo {
	height: 72px;
}

#logo img {
	max-height: 42px;
	margin: 12px;
}

@media (max-width: 1199.98px) {
	#header {
		position: absolute;
	}

	.dropdown-container {
		position: relative;
		border: none;
		margin-top: -24px;
	}

	.dropdown {
		display: table;
	}

	.dropdown .dropdown-main-button {
		width: 100%;
		text-align: left;
	}
}

@media (min-width: 1200px) {
	.menu-link {
		padding: 0 15px !important;
	}
}
