#slogan {
	margin-top: 12px;
	padding-bottom: 12px !important;
}

.slider-inner-element {
	width: 100%;
	height: 100%;
}

.button {
	border-radius: 20px;
	padding: 12px 16px !important;
	text-transform: uppercase !important;
}

.slider-element {
    height: calc(100vh - 72px) !important;
    min-height: 0vh !important;
}

#main-logo {
    max-height: 10vh;
	margin-bottom: 20px;
}

@media (max-width: 374.98px) {
	.emphasis-title h1 {
		font-size: 32px !important;
	}

	.emphasis-title {
        margin-bottom: 12px;
    }

	#main-logo {
		margin-bottom: 0;
	}
}

@media (max-height: 499.98px) {
    .emphasis-title h1 {
		font-size: 32px !important;
	}

    .emphasis-title {
        margin-bottom: 12px;
    }

    #main-logo {
        margin-bottom: -20px;
    }
}

@media (max-width: 639.98px) {
    .button {
        font-size: 14px !important;
    }
}

@media (max-width: 1199.98px) {
    .slider-element {
        height: 100vh !important;
        top: 36px;
    }
}

@media (min-width: 1200px) {
	#main-container {
		padding-top: 72px;
	}

	.slider-element {
		top: 0;
	}
}
