.loading-container-outer {
    display: table;
    position: fixed;
    width: 100%;
    height: calc(100vh - 72px);
    z-index: 99;
    background-color: #000c;
    text-align: center;
}

.loading-container-inner {
    display: table-cell;
    vertical-align: middle;
}

.loading-image {
    width: 64px;
    animation: loading-image 1s linear infinite;
}

@keyframes loading-image {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@media screen and (max-width: 1200px) {
    .loading-container-outer {
        height: calc(100vh);
    }
}
